<template>
  <p
    class="space-x-1 text-clip font-agrandir-wide text-sm md:flex md:gap-1 md:space-x-0"
  >
    <slot name="beforePrice" />
    <span>{{ formattedPrice }}</span>
    <slot name="afterPrice" />
  </p>
</template>

<script setup lang="ts">
const props = defineProps<{
  value: number | undefined
}>()

const { currencyCode } = usePrice()

const formatter = new Intl.NumberFormat('de-CH', {
  style: 'currency',
  currency: currencyCode.value,
})

const formattedPrice = computed(() => {
  const price = props.value
  if (price !== undefined) {
    return formatter.format(price)
  } else {
    return '-'
  }
})
</script>
